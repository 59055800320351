import React from 'react'
import '../Skins.css'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';


function Delta() {
    useEffect(() => {
        document.title = "delta! skinhub";
      }, []);
  return (
    <div>
        <div className='skins-bg'>    
        
       <h1 className='skins-title'> 
       <a className='skins-link' href='https://osu.ppy.sh/users/8523723'>
        delta <img className='avatar' src='http://s.ppy.sh/a/8523723?517'></img>
        </a>
        </h1>
        <div className='skins-container'>
            <div className='skins'>
                <div className='bdt'>
                    <a href='https://www.mediafire.com/file/0fblvbdsh4j8ik0/bdt_deltaedit.osk/file'>
                    <img className='bdt-gif'src='https://be.s-ul.eu/mQbKF4DG'></img>
                    <p className='bdt-title'>bdt_delta</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/0dwczfr5legxaxr/hai.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/xngyIsbG'></img>
                    <p className='elohere-title'>hai</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/gz8sjhp0svppoeo/azer8dusk_delta.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/yeSRDclU'></img>
                    <p className='elohere-title'>azer8dusk_delta</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/tbgc5mezvz2z6fk/d_rafis.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/uxFzyp1L'></img>
                    <p className='elohere-title'>d_rafis</p> 
                    </a>
                </div>
            </div>
        <div className='skins'>
                <div className='bdt'>
                    <a href='https://www.mediafire.com/file/j0awa20j0nd6yzv/s_rafis.osk/file'>
                    <img className='bdt-gif'src='https://be.s-ul.eu/K0YR1r7g'></img>
                    <p className='bdt-title'>s_rafis</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/g8q1lpk5nuxdgbg/523d.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/J5QtO6mF'></img>
                    <p className='elohere-title'>523d</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/vyji7mfufa0lc42/dafonz_deltaver.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/IORgGoX5'></img>
                    <p className='elohere-title'>dafonz_deltaver</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/tk48s94zrqjw651/deltahddt.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/7HRTMz13'></img>
                    <p className='elohere-title'>deltahddt</p> 
                    </a>
                </div>
            </div> 
            <div className='skins'>
                <div className='bdt'>
                    <a href='https://www.mediafire.com/file/wt4wq5li9yms59m/shiva.osk/file'>
                    <img className='bdt-gif'src='https://dtta.s-ul.eu/Atzrbb9l'></img>
                    <p className='bdt-title'>shiva</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/ioolkylgtso4lu2/d_pink.osk/file'>
                    <img className='elohere-gif'src='https://dtta.s-ul.eu/YHW9pGZM'></img>
                    <p className='elohere-title'>d_pink</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/yov22ic25xq6hcg/secret_brigade.osk/file'>
                    <img className='elohere-gif'src='https://dtta.s-ul.eu/Dqfr0QCJ'></img>
                    <p className='elohere-title'>secret brigade</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/08uggs4lh1toz0r/love_me%252Clove_me%2521.osk/file'>
                    <img className='elohere-gif'src='https://dtta.s-ul.eu/IeD30Ma7'></img>
                    <p className='elohere-title'>love me, love me!</p> 
                    </a>
                </div>
            </div>
            <div className='skins'>
                <div className='bdt'>
                    <a href='https://www.mediafire.com/file/kxtiozszip8ypj5/redelta_has_adhd.osk/file'>
                    <img className='bdt-gif'src='https://dtta.s-ul.eu/lhH2RA8q'></img>
                    <p className='bdt-title'>redelta has adhd</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/8g0krr9963iw0bg/delta_has_adhd.osk/file'>
                    <img className='elohere-gif'src='https://dtta.s-ul.eu/Pm5KU5Ki'></img>
                    <p className='elohere-title'>delta has adhd</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/e86v3ida9a6b2e0/Simulation_Swarm.osk/file'>
                    <img className='elohere-gif'src='https://dtta.s-ul.eu/0u2dlLF1'></img>
                    <p className='elohere-title'>Simulation Swarm</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/41f8o68jya4mr7d/CROSSING.osk/file'>
                    <img className='elohere-gif'src='https://dtta.s-ul.eu/NhYzAhLL'></img>
                    <p className='elohere-title'>CROSSING</p> 
                    </a>
                </div>
            </div>
        </div>
    </div>
       </div>
  )
}

export default Delta
