import React from 'react'
import './Modal.css'


function Modal({ closeModal }) {
  return (
    <div className='modal-bg'>
        <div className='modal-container'>
            <div className='modal-header'>
                <button onClick={() => closeModal(false)}> ← </button>
                <h1 className='setup'>my setup!</h1>
            </div>
            <div className='modal-body'>
                <a className='gpu'>gtx 1070 zotac</a> <br></br>
                <a className='cpu'>r7 5700x3d</a> <br></br>
                <a className='mobo'>b550m-a prime</a> <br></br>
                <a className='monitor'>acer vq240y 180hz</a> <br></br>
                <a className='tablet'>ctl-480</a> <br></br>
                <a className='mouse'>attack shark x3</a> <br></br>
                <a className='kb'>drunkdeer a75</a> <br></br>
                <a className='kb'>artisan zero soft xl</a> <br></br>
            </div>
        </div>
    </div>
  )
}

export default Modal
